export const Email = () => import('./../../components/Email.vue')
export const PageAbout = () => import('./../../components/PageAbout.vue')
export const PageIndex = () => import('./../../components/PageIndex.vue')
export const PageMoments = () => import('./../../components/PageMoments.vue')
export const PagePosts = () => import('./../../components/PagePosts.vue')
export const PageTest = () => import('./../../components/PageTest.vue')
export const PostImage = () => import('./../../components/PostImage.vue')
export const RewardCode = () => import('./../../components/RewardCode.vue')
export const ScrollableTable = () => import('./../../components/ScrollableTable.vue')
export const VideoPlayer = () => import('./../../components/VideoPlayer.vue')
export const globalComponents: string[] = ["ProseA","ProseBlockquote","ProseCode","ProseEm","ProseH1","ProseH2","ProseH3","ProseH4","ProseH5","ProseH6","ProseHr","ProseImg","ProseLi","ProseOl","ProseP","ProsePre","ProseScript","ProseStrong","ProseTable","ProseTbody","ProseTd","ProseTh","ProseThead","ProseTr","ProseUl","Icon"]
export const localComponents: string[] = ["Email","PageAbout","PageIndex","PageMoments","PagePosts","PageTest","PostImage","RewardCode","ScrollableTable","VideoPlayer"]